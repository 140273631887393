<template>
  <div class="relative bg-promy-gray-50 flex flex-1">
    <SideBar />
    <div class="flex flex-1 flex-col full-hd:ml-96">
      <Navbar @showSideBar="showSideBar" />
      <div class="flex flex-1 bg-promy-gray-50 minimized-sidebar:ml-16">
        <router-view />
      </div>
    </div>
    <PopupGuest></PopupGuest>
    <portal-target name="popup-inser-email"></portal-target>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'
import PopupGuest from '../components/Popup/PopupGuest.vue'

export default {
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {}
  },
  mounted() {
    if (
      this.isLoggedIn &&
      !this.user.verified_telephone_at &&
      !this.user.email_verified_at
    ) {
      this.$router.push('/verify-email')
    } else if (
      this.$route.meta.inside_address &&
      !this.$route.params.id_address
    ) {
      this.$router.push('/sections-gu/adresse/')
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },
  methods: {
    showSideBar(value) {
      this.$store.commit('sidebar/SET_ACTIVATED_SIDEBAR', value)
    },
  },
  components: { PopupGuest },
}
</script>
<style lang="scss" scoped></style>
